$(function () {
	'use strict';

	// 定数定義
	const BREAK_POINT_MD = 768;
	const $pagetopBtn = $('.c-footer__pagetop');
	const $otherBtn = $('.c-nav-global__sub-button');
	const $globalNav = $('.c-header__global-nav');
	const $hamburgerBtn = $('.c-header__nav-button');
	const $otherMenu = $('.c-nav-global__sub-list');
	const $overlay = $('.c-nav-global__spmenu-overlay');
	const $hanburgerTxt = $('.c-header__nav-button-txt');

	//objectfit IE対応
	objectFitImages();

	/**
	 * slickslider
	 */
	function slickSlider() {
		$('.c-page-home__hero-slideshow').slick({
			dots: true,
			arrows: true,
			infinite: true,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			centerMode: true,
			touchMove: true,
			variableWidth: true,
			prevArrow: '<button class="c-page-home__hero-slide-arrow-prev"><img src="/img/top/icon-arrow-left.svg"></button>',
			nextArrow: '<button class="c-page-home__hero-slide-arrow-next"><img src="/img/top/icon-arrow-right.svg"></button>',
			responsive: [
				{
					breakpoint: BREAK_POINT_MD,
					settings: {
						variableWidth: false,
						dots: false,
						arrows: false,
						slidesToShow: 1,
					},
				},
			],
		});
		$('.lineup-slider').slick({
			dots: true,
			arrows: true,
			infinite: true,
			slidesToScroll: 1,
			autoplay: false,
			autoplaySpeed: 4000,
			arrows: false,
			centerMode: true,
			touchMove: true,
			variableWidth: true,
			responsive: [
				{
					breakpoint: BREAK_POINT_MD,
					settings: {
						variableWidth: false,
						slidesToShow: 1,
					},
				},
			],
		});
	}
	slickSlider();

	/**
	 * timer
	 */
	function waitFinalMoment(timer, callback, speed) {
		clearTimeout(timer);
		timer = setTimeout(callback, speed);
	}

	/**
	 * ハンバーガーメニュー
	 */
	const hamburger = {
		toggle: function () {
			if ($hamburgerBtn.attr('aria-expanded') == 'false') {
				//ハンバーガーメニュー展開
				var wh = $(window).height();
				var hh = $('.c-header').innerHeight();

				$hamburgerBtn.attr('aria-expanded', 'true');
				$globalNav.attr('aria-hidden', 'false').css('max-height', wh - hh);
				$overlay.attr('aria-hidden', 'false');
				$hanburgerTxt.text('close');
			} else {
				//ハンバーガーメニュー閉じる
				$hamburgerBtn.attr('aria-expanded', 'false');
				$globalNav.attr('aria-hidden', 'true').removeAttr('style');
				$overlay.attr('aria-hidden', 'true').removeAttr('style');
				$hanburgerTxt.text('menu');

				$('html').removeAttr('style');
			}
		},
		resize: function () {
			var wh = $(window).height();
			var hh = $('.c-header').innerHeight();

			$globalNav.css('max-height', wh - hh);
			// $overlay.css('height', wh - hh);
		},
		close: function () {
			$hamburgerBtn.attr('aria-expanded', 'false');
			$globalNav.attr('aria-hidden', 'true').removeAttr('style');
			$overlay.attr('aria-hidden', 'true').removeAttr('style');
			$hanburgerTxt.text('menu');
			$('html').removeAttr('style');
		},
	};

	//ページトップへ戻るボタンをクリックした時
	$pagetopBtn.on('click', function () {
		$('body,html').animate({ scrollTop: 0 }, 500);
	});

	//pc時、otherメニューボタンをクリックした時
	$otherBtn.on('click', function () {
		if ($(this).attr('aria-expanded') == 'false') {
			$(this).attr('aria-expanded', 'true');
			$otherMenu.attr('aria-hidden', 'false');
		} else {
			$(this).attr('aria-expanded', 'false');
			$otherMenu.attr('aria-hidden', 'true');
		}
	});

	//sp時、ハンバーガーメニューをクリックした時
	$hamburgerBtn.on('click', function () {
		hamburger.toggle();
		//ハンバーガーメニュー展開時、オーバーレイをタップした際はメニューを閉じる
		$overlay.on('click', function () {
			hamburger.close();
		});
	});

	//スクロール時
	$(window).on('load scroll', function () {
		var sc = $(window).scrollTop();
		var bh = $(document).height();
		var wh = $(window).height();
		var fh = $('.c-footer').innerHeight();

		if (sc > 200) {
			$pagetopBtn.attr('aria-hidden', 'false');
		} else {
			$pagetopBtn.attr('aria-hidden', 'true');
		}

		//フッターまで来たらトップページへ戻るボタンをフッターに固定
		if (bh - (sc + wh) <= fh) {
			$pagetopBtn.attr('data-pagetop-fixed', 'false');
		} else {
			$pagetopBtn.attr('data-pagetop-fixed', 'true');
		}
	});

	//リサイズ時
	var resizeTimer;
	$(window).on('load resize orientationchange', function () {
		waitFinalMoment(
			resizeTimer,
			function () {
				if ($(window).width() < BREAK_POINT_MD) {
					$otherMenu.attr('aria-hidden', 'false');
					hamburger.resize();
				} else {
					$otherMenu.attr('aria-hidden', 'true');
					hamburger.close();
				}
				if (Math.abs(window.orientation) === 90) {
					hamburger.close();
				}
			},
			100
		);
	});
});
